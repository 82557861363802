export class SuspenseDetails {
  constructor($form) {
    this.form = $form;
    this.form.enableClientSideValidations();
  }

  static get fieldsToCapture() {
    return [
      'name',
      'reference',
      'property_reference',
      'category',
      'comments',
    ];
  }

  isValid() {
    const validations = this.form[0].ClientSideValidations.settings.validators;
    return this.form.isValid(validations);
  }

  params() {
    const params = {};
    this.constructor.fieldsToCapture.forEach((element) => {
      params[element] = $(`#suspense_payment_details_details_${element}`).val();
    });
    return params;
  }
}
