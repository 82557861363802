document.addEventListener('DOMContentLoaded', () => {
  const toggableElements = document.querySelectorAll('.js-toggable');
  const toggleTargets = document.querySelectorAll('.js-target');
  const toggleButtons = document.querySelectorAll('.js-toggle');

  const displayErrors = () => {
    const errored = Array.from(toggleTargets).some((target) => target.classList.contains('has-error'));
    if (errored) {
      toggleTargets.forEach((target) => {
        if (target.classList.contains('has-error')) {
          target.classList.remove('d-none');
        } else {
          target.classList.add('d-none');
        }
      });
    }
  };

  const disableHiddenTargets = () => {
    toggleTargets.forEach((target) => {
      if (target.classList.contains('d-none')) {
        ['input', 'select'].forEach((selector) => {
          const element = target.querySelector(selector);
          if (element) {
            element.disabled = true;
          }
        });
      }
    });
  };

  const transposeToggledAndAltText = (button) => {
    const textSpan = button.querySelector('span.input-toggle-text');
    if (textSpan) {
      const toggledText = textSpan.textContent;
      const altText = button.getAttribute('data-alt');

      button.setAttribute('data-alt', toggledText);
      textSpan.textContent = altText;
    }
  };

  const toggleHideOrShow = (element) => {
    element.classList.toggle('d-none');
  };

  const toggleDisabledOrEnabled = (element) => {
    ['input', 'select'].forEach((selector) => {
      const target = element.querySelector(selector);
      if (target) {
        target.disabled = !target.disabled;
      }
    });
  };

  const focusInputIfShown = (element) => {
    if (!element.classList.contains('d-none')) {
      const input = element.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  };

  if (toggableElements.length > 0) {
    displayErrors();
    disableHiddenTargets();

    if (toggleButtons.length > 0) {
      toggleButtons.forEach((button) => {
        button.addEventListener('click', (e) => {
          e.preventDefault();
          const toggableElement = e.target.closest('.js-toggable');
          const targetElements = toggableElement.querySelectorAll('.js-target');

          transposeToggledAndAltText(button);
          targetElements.forEach((target) => {
            toggleHideOrShow(target);
            toggleDisabledOrEnabled(target);
            focusInputIfShown(target);
          });
        });
      });
    }
  }
});
