$(document).ready(() => {
  function processNode($node) {
    const children = $node.find('> .result-node');
    const total = children.length;
    const min = 2;

    $node.find('> .result-node').each((i, el) => {
      const $el = $(el);

      if (total > min) {
        if (i) {
          $el.hide();
        } else if (total - 1) {
          const cat = $el.data('category') || '';
          const expandText = `View more ${cat} (${total - 1})`;
          const collapseText = `View fewer ${cat}`;
          let collapsed = true;
          const $link = $('<a href="#"></a>')
            .text(expandText)
            .on('click', (e) => {
              e.preventDefault();
              children.filter(':not(:first)').toggle();
              collapsed = !collapsed;
              $(e.target)
                .toggleClass('expanded')
                .text(collapsed ? expandText : collapseText);
            });
          $el.append($link);
        }
      }

      processNode($el);
    });
  }

  $('#repair-spaces-results-form > .result-node').each((_, el) => {
    processNode($(el));
  });

  $('input[name="property_id"]').on('click', function toggleButtonClass() {
    $('.repair-request-btn').addClass('d-none');
    $(this).parent().find('.repair-request-btn').removeClass('d-none');
  });

  // This will trigger the click event for preselected property
  $('input[name="property_id"]:checked').trigger('click');
});
