import TinyGesture from 'tinygesture';

const swipeToDismiss = ($element, callback) => {
  let swiped = false;
  let startOffset = 0;
  const decelerationOnOverflow = 4;

  const $panel = $('<div class="swipe__delete_panel"></div>');
  $panel.hide();
  $element.append($panel);

  const target = $element.get(0);
  const gesture = new TinyGesture(target, { mouseSupport: false });
  gesture.on('panstart', (e) => {
    e.stopPropagation();
  });

  gesture.on('panmove', (e) => {
    e.stopPropagation();
    const revealWidth = ($element.width() / 2) + 15;
    const snapWidth = revealWidth;
    if (gesture.animationFrame) {
      return;
    }
    gesture.animationFrame = window.requestAnimationFrame(() => {
      if ($(window).width() >= 990) {
        return;
      }
      const getX = (x) => {
        if (x > 0) {
          return 0;
        }
        if (x < revealWidth && x > -revealWidth) {
          return x;
        }
        if (x < -revealWidth) {
          return (x + revealWidth) / decelerationOnOverflow - revealWidth;
        }
        return 0;
      };
      const newX = getX(startOffset + gesture.touchMoveX);
      target.style.transform = `translateX(${newX}px)`;
      if (newX <= -snapWidth) {
        swiped = newX < 0 ? -revealWidth : revealWidth;
        target.style.boxShadow = '0 0 0 3px #DD080D';
      } else {
        swiped = false;
        $panel.show();
        target.style.boxShadow = null;
      }
      window.requestAnimationFrame(() => {
        target.style.transition = null;
      });
      gesture.animationFrame = null;
    });
  });

  gesture.on('panend', () => {
    window.cancelAnimationFrame(gesture.animationFrame);
    gesture.animationFrame = null;
    window.requestAnimationFrame(() => {
      target.style.transition = 'transform .2s ease-in';
      if (!swiped) {
        startOffset = 0;
        target.style.transform = null;
        setTimeout(() => {
          $panel.hide();
        }, 200);
      } else {
        startOffset = 0;
        target.style.transform = `translateX(${-$element.outerWidth() - ($('body').width() - $element.outerWidth())}px)`;
        setTimeout(() => {
          $panel.hide();
          callback();
        }, 250);
      }
    });
  });
};

$(() => {
  if ($('.notifications-block').length) {
    $('.notifications-block').each((index, element) => {
      swipeToDismiss($(element), () => {
        $(element).find('.notifications-block__dismiss').trigger('click');
      });
    });
  }
});
