/**
* Character Counter v1.5.1
* ======================
*
* Character Counter is a simple, Twitter style character counter.
*
* https://github.com/dtisgodsson/jquery-character-counter
*
* @author Darren Taylor
* @author Email: shout@darrenonthe.net
* @author Twitter: darrentaytay
* @author Website: http://darrenonthe.net
*
*/

$.fn.extend({
  characterCounter(opts) {
    const defaults = {
      exceeded: false,
      counterSelector: false,
      countNewLineChars: false,
      limit: 150,
      renderTotal: false,
      counterWrapper: 'span',
      counterCssClass: 'counter',
      counterFormat: '%1',
      counterExceededCssClass: 'exceeded',
      increaseCounting: false,
      onExceed() {},
      onDeceed() {},
      customFields: {},
    };

    const options = $.extend(defaults, opts);

    function customFields(params) {
      let html = '';

      if (params && params.length > 0) {
        Object.entries(params).each((i) => {
          html += ` ${i}="${params[i]}"`;
        });
      }

      return html;
    }

    function generateCounter() {
      let classString = options.counterCssClass;

      if (options.customFields.class) {
        classString += ` ${options.customFields.class}`;
        delete options.customFields.class;
      }

      return `<${options.counterWrapper}${customFields(options.customFields)} class="${classString}"></${options.counterWrapper}>`;
    }

    function renderText(count) {
      let renderedCount = options.counterFormat.replace(/%1/, count);

      if (options.renderTotal) {
        renderedCount += `/${options.limit}`;
      }

      return renderedCount;
    }

    function checkCount(element) {
      let characterCount = $(element).val().length;

      if (options.countNewLineChars) {
        characterCount += $(element).val().split(/\r*\n/).length;
      }

      const counter = options.counterSelector ? $(options.counterSelector) : $(element).nextAll(`.${options.counterCssClass}`).first();
      let remaining = options.limit - characterCount;
      let condition = remaining < 0;

      if (options.increaseCounting) {
        remaining = characterCount;
        condition = remaining > options.limit;
      }

      if (condition) {
        counter.addClass(options.counterExceededCssClass);
        options.exceeded = true;
        options.onExceed(characterCount);
      } else if (options.exceeded) {
        counter.removeClass(options.counterExceededCssClass);
        options.onDeceed(characterCount);
        options.exceeded = false;
      }

      counter.html(renderText(remaining));
    }

    function bindEvents(element) {
      $(element)
        .on('input change', () => {
          checkCount(element);
        });
    }

    return this.each(() => {
      const html5len = $(this).attr('maxlength');
      if (typeof html5len !== typeof undefined && html5len !== false) {
        $.extend(defaults, {
          limit: parseInt($(this).attr('maxlength'), 10),
        });
      }
      if (!options.counterSelector) {
        $(this).after(generateCounter());
      }
      bindEvents(this);
      checkCount(this);
    });
  },
});
