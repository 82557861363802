import Rollbar from '../src/rollbar';

export class PaymentConfirmation {
  constructor(stripe, $form) {
    this.form = $form;
    this.api = stripe;
    this.setUp();
  }

  clientSecret() {
    return this.form.find('#payment_intent_client_secret').val();
  }

  handleConfirmationSuccess(paymentIntent) {
    this.form.find('#payment_intent_id').val(paymentIntent.id);
    this.form.find('#payment_method_id').val(paymentIntent.payment_method);
    return this.form.trigger('submit');
  }

  failedPaymentConfirmationPath() {
    return this.form.find('#failed_payment_confirmations_path').val();
  }

  handleConfirmationFailure(error) {
    this.form.find('#payment_confirmation_error_code').val(error.code);
    this.form.find('#payment_confirmation_error_message').val(error.message);
    this.form.attr('action', this.failedPaymentConfirmationPath());

    if (error.payment_intent) {
      this.form.find('#payment_intent_id').val(error.payment_intent.id);
      this.form.find('#payment_method_id').val(error.payment_intent.payment_method);
      return this.form.trigger('submit');
    }
    return this.setPaymentIntentIdsFromStripe();
  }

  setPaymentIntentIdsFromStripe() {
    return this.api.retrievePaymentIntent(this.clientSecret())
      .then(
        (result) => {
          if (result.error) {
            const message = 'Card confirmation failure: payment intent could not be retrieved';
            return Rollbar.error(message, result.error);
          }
          this.form.find('#payment_intent_id').val(result.paymentIntent.id);
          this.form.find('#payment_method_id').val(result.paymentIntent.payment_method);
          return this.form.trigger('submit');
        },
        (err) => {
          const message = 'Card confirmation error: payment intent could not be retrieved';
          return Rollbar.error(message, err);
        },
      );
  }

  handleError(message) {
    this.form.find('#payment_confirmation_error_code').val('IntegrationError');
    this.form.find('#payment_confirmation_error_message').val(message);
    this.form.attr('action', this.failedPaymentConfirmationPath());

    return this.form.trigger('submit');
  }

  handleException(error) {
    const details = `${error.message} -> ${error.stack}`;
    this.form.find('#payment_confirmation_error_code').val('Exception');
    this.form.find('#payment_confirmation_error_message').val(details);
    this.form.attr('action', this.failedPaymentConfirmationPath());

    return this.form.trigger('submit');
  }

  setUp() {
    try {
      return this.api.handleCardAction(this.clientSecret())
        .then(
          (result) => {
            if (result.error) {
              return this.handleConfirmationFailure(result.error);
            }
            return this.handleConfirmationSuccess(result.paymentIntent);
          },

          (err) => this.handleError(err),
        );
    } catch (error) {
      const err = error;
      return this.handleException(err);
    }
  }
}

$(document).ready(() => {
  if (
    typeof window.stripeMode === 'undefined'
    || window.stripeMode === null
    || $('body').data('controller') !== 'payment_confirmations'
  ) {
    return;
  }

  if (window.stripeMode === 'live') {
    const stripePublicKey = $('#stripe_public_key').val();
    const $form = $('#new_payment_confirmation');
    new PaymentConfirmation(Stripe(stripePublicKey), $form); /* eslint-disable-line no-new */
  }
});
