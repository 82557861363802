import consumer from './consumer';

const connectToMagic = () => {
  const magicCode = $('input[name=magic_code]').val();

  consumer.subscriptions.create({ channel: 'MagicCodeChannel', code: magicCode }, {
    received(data) {
      if (data.verified) {
        window.location.href = data.reset;
      }
    },
  });
};

$(() => {
  if ($('.magic-code-page').length) { connectToMagic(); }
});
