$(document).ready(() => $('.js-character-limit').each((i, el) => {
  const $el = $(el);
  const maxlength = $el.attr('maxlength');
  $el.removeAttr('maxlength');
  const errorMessage = $el.data('max-length-error') || 'Text is too long.';

  return $el.characterCounter({
    limit: maxlength,
    counterFormat: `
        <span class="warn">${errorMessage}</span>
        %1/${maxlength.toString()} characters used
      `,
    increaseCounting: true, // count up, not down to the limit
  });
}));
