$(document).ready(() => {
  const $container = $('.js-related-repairs');
  if ($container.length === 0) return;

  const $status = $container.find('.js-display-status');
  const $display = $('<span />');
  const { maxRelatedRepairsAtATime, totalRepairs } = $container.data();
  const state = {
    current: maxRelatedRepairsAtATime,
    total: totalRepairs,
  };

  function updateDisplay() {
    $display.text(`${state.current} / ${state.total} repairs `);
  }

  function revealRepairs() {
    $container.find(`ul li:nth-child(-n+${state.current})`).show();
    if (state.current >= state.total) $status.remove();
  }

  const $viewMore = $('<a href=# />')
    .text('View more')
    .on('click', (e) => {
      e.preventDefault();
      state.current += maxRelatedRepairsAtATime;
      revealRepairs();
      updateDisplay();
    });

  $status.append($display).append($viewMore);
  updateDisplay();
});
