document.addEventListener('DOMContentLoaded', () => {
  const formTypeElements = document.getElementsByName('form[form_type]');
  const repairFormSelect = document.getElementById('form_form_type_repair');
  const contactCategoryDropdown = document.getElementById('form_contact_category');

  function updateContactCategoryDropdown() {
    contactCategoryDropdown.disabled = repairFormSelect.checked;
    contactCategoryDropdown.value = repairFormSelect.checked ? '' : contactCategoryDropdown.value;
  }

  if (formTypeElements.length > 0) {
    formTypeElements.forEach((element) => {
      element.addEventListener('change', updateContactCategoryDropdown);
    });

    updateContactCategoryDropdown();
  }
});
