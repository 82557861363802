// Prevent characters that are not numerical, commas or full stops.
//
// - The normal case is that the number input field is recognised and
// the device will disable use of unintended characters
// - iOS on iPads recognise the number input type but display a
// keyboard with special characters which can be used. Some advise to
// switch to the tel keyboard but this disables the use of full stops
// and commas. This snippet of code disables special characters.
// - Firefox recognises the number field but allows alphabetical
// characters, provide a better user experience by preventing them.
//

const inputSelector = 'input.amount';
const regex = /^[0-9.]+$/;
const validControls = [
  0, // arrows
  8, // backspace
  32, // return
  13, // enter
];
const addError = (element) => $(element).addClass('field-invalid');
const clearError = (element) => $(element).removeClass('field-invalid');
const validCurrency = (string) => regex.test(string);
const validControl = (string) => Array.from(validControls).includes(string);

$(document).on('keypress', inputSelector, (e) => {
  clearError(e.target);

  const key = !e.charCode ? e.which : String.fromCharCode(e.charCode);

  if (!(validCurrency(key) || validControl(e.which))) {
    addError(e.target);
    e.preventDefault();
  }
});
