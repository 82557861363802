$(document).on('payment.load', () => {
  const hidePaymentForm = () => {
    $('#payment-card-form input').attr('disabled', 'disabled');
    $('#payment-card-form').addClass('d-none');
    $('#payment-card-form-container').removeClass('d-none');
  };

  const showPaymentForm = () => {
    let firstInput;
    $('[id^=payment_card_alias_]').attr('checked', null);
    $('#payment-card-form input').attr('disabled', null);

    $('#payment-card-form').removeClass('d-none');
    $('#payment-card-form-container').addClass('d-none');

    $('#new_payment').enableClientSideValidations();

    [firstInput] = $('#payment-details input');
    if (firstInput) {
      firstInput.focus();
      return;
    }

    [firstInput] = $('#payment-card-form input');
    if (firstInput) {
      firstInput.focus();
    }
  };

  if (
    $('[id^=payment_card_alias_]').length > 0
    && $('.notifications .error').length === 0
  ) {
    hidePaymentForm();
  } else {
    showPaymentForm();
  }

  $('#payment-card-form-btn').on('click', showPaymentForm);

  $('[id^=payment_card_alias_]').on('change', () => hidePaymentForm());
});
