/**
 * Fill a field value on click;
 * Usage:
 * <input id="field" />
 * <button data-behaviour="fill-value" data-fill-target="#field" data-fill-value="anything" >
 */
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('[data-behaviour~="fill-value"]').forEach((btn) => {
    btn.addEventListener('click', () => {
      const { fillTarget, fillValue } = btn.dataset;

      const targetEl = document.querySelector(fillTarget);
      if (targetEl) {
        targetEl.value = fillValue;
      }
    });
  });
});
