export class StripeFields {
  constructor(stripe) {
    this.api = stripe;
    this.elements = this.api.elements({
      fonts: [{
        cssSrc: 'https://s3-eu-west-1.amazonaws.com/assets.my.tvha.co.uk/font.css',
      }],
    });
  }

  setUp() {
    this.card_number = this.createStripeIframe('cardNumber', 'card-number', '•••• •••• •••• ••••');
    this.createStripeIframe('cardExpiry', 'card-expiry', 'MM/YY');
    this.createStripeIframe('cardCvc', 'card-cvc', '•••');
    this.createStripeIframe('postalCode', 'card-post-code', '');
  }

  submit(optionalArgs, callback) {
    return this.api.createPaymentMethod(
      'card',
      this.card_number,
      { billing_details: { name: optionalArgs.name } },
    ).then((result) => {
      if (!result.error) {
        return callback('success', result.paymentMethod.id);
      } if (result.error.type !== 'validation_error') {
        return callback('generic_error', null, null);
      }
      return callback('validation_error', null, null);
    });
  }

  createStripeIframe(stripe, id, placeholder) {
    const testId = `stripe-iframe-${id}`;
    const baseClasses = `${testId} StripeElement`;

    const style = {
      base: {
        fontSize: '19px',
        lineHeight: '21px',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        color: '#63686d',
        letterSpacing: 'normal',
        '::placeholder': {
          color: '#8e8e8e',
        },
      },
      invalid: {
        color: '#ce0a31',
      },
    };

    const stripeEl = this.addElement(stripe, baseClasses, style, placeholder);

    if ($(`#${id} .stripe-element-placeholder`).length) {
      stripeEl.mount(`#${id} .stripe-element-placeholder`);

      stripeEl.on('change', (event) => {
        const element = $(`#${id}`);
        if (event.error) {
          element.find('.errors').text(event.error.message);
          return element.addClass('has-error');
        }
        return element.removeClass('has-error');
      });
    }

    return stripeEl;
  }

  addElement(stripe, baseClasses, style, placeholder) {
    return this.elements.create(stripe, {
      classes: { base: baseClasses },
      style,
      placeholder,
    });
  }
}
