$(document).ready(() => $('[data-unsaved-changes-prompt]')
  .each((form) => $(form).data('state', $(form).serialize()))
  .on('submit', () => {
    window.onbeforeunload = undefined;
  }));

window.onbeforeunload = () => {
  let prompt;
  $('[data-unsaved-changes-prompt]').each(() => {
    if ($(this).data('state') !== $(this).serialize()) {
      prompt = 'Changes that you made may not be saved';
    }
  });
  return prompt;
};
