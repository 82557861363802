/**
 * Handle good to know message link clicks and dismiss the message at the
 *  same time as following link. Copied from old code: Probably not the best way to do this
 */
document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.content-link').forEach((link) => {
    const { messageUrl } = link.dataset;

    if (messageUrl) {
      link.addEventListener('click', () => {
        $.ajax({ url: messageUrl, method: 'delete' });
      });
    }
  });
});
