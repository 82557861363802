document.addEventListener('DOMContentLoaded', () => {
  const collapsibleNavbar = document.querySelector('#collapsibleNavbar');

  function navBarExpanded() {
    return collapsibleNavbar.classList.contains('in');
  }

  function clickedOutsideNavbar(clickedSpot) {
    return !collapsibleNavbar.contains(clickedSpot);
  }

  function collapseNavbar() {
    $(collapsibleNavbar).collapse('hide');
  }

  if (collapsibleNavbar) {
    document.addEventListener('click', (event) => {
      if (navBarExpanded() && clickedOutsideNavbar(event.target)) {
        collapseNavbar();
      }
    });
  }
});
