import Rollbar from '../src/rollbar';
import consumer from './consumer';
import {
  hideAllRetryButtons,
  enableCaptureButtons,
  disableSubmitButton,
  disableCaptureButtons,
  handleReceivedUpdate,
  FIELDS_TO_CAPTURE,
  COMPLETE_PAYMENT_BUTTON_ID,
  checkValidFields,
} from './pci_payment/twilio_capture';
import { SuspenseDetails } from './pci_payment/suspense_details';

let pciPaymentFormLoaded = false;

const sessionFailedToStart = () => {
  $('#mask-status').hide();
  $('#mask-error').show();
  Rollbar.error('PCI Payment session failed to start');
};

const connectToPciPayment = ($form) => {
  const paymentReference = $form.data('payment-reference');
  const callSid = $form.data('twilio-call-sid');
  const paymentSid = $form.data('twilio-payment-sid');
  const startSessionTimeoutInSeconds = $form.data('start-session-timeout-in-seconds');
  const amount = $form.data('amount');
  const date = $form.data('date');
  const agreementId = $form.data('agreement-id');

  window.paymentSessionTimeout = setTimeout(
    () => { sessionFailedToStart(); },
    +startSessionTimeoutInSeconds * 1000,
  );

  consumer.subscriptions.create({ channel: 'PciPaymentChannel', payment_reference: paymentReference }, {
    connected() {
      if (callSid && paymentSid) {
        this.enableForm($form);
      } else if (!pciPaymentFormLoaded) {
        $('#mask-status').html('<strong>Please wait...</strong>');
      }
      this.setupButtons();
      this.perform('update_status');
    },

    received(data) {
      switch (data.event) {
        case 'PaymentSessionStarted':
          this.enableForm($form);
          break;

        case 'PaymentCompleteSuccess':
          window.location.href = data.redirect_path;
          break;

        case 'PaymentCompleteFailure':
          $('.page-has-error').addClass('grey-out');
          $('#js-error-notification-general').removeClass('d-none')
            .find('.notification-contents')
            .html(`<span class='sr-only'>Error: </span>${data.errors.join('<br>')}`);
          window.scrollTo(0, 0);
          break;

        default:
          handleReceivedUpdate(data);
      }
    },

    enableForm() {
      clearTimeout(window.paymentSessionTimeout);
      $form.show();
      $('#mask-status').html('<i class="fa fa-check-circle"></i> <span>The call is masked</span>');
      hideAllRetryButtons();
      enableCaptureButtons();
      this.setupSuspenseDetails();
      pciPaymentFormLoaded = true;
    },

    // custom methods
    setupSuspenseDetails() {
      if ($('#new_suspense_payment_details').length) {
        this.suspenseDetails = new SuspenseDetails($('#new_suspense_payment_details'));
      }
    },

    isValid() {
      if (this.suspenseDetails) {
        return this.suspenseDetails.isValid();
      }
      return true;
    },

    setupButtons() {
      FIELDS_TO_CAPTURE.forEach((field) => {
        $(`#${field}`).on('click', () => this.initiateCapture(field));
        $(`#retry-${field}`).on('click', () => this.initiateCapture(field));
      });

      $(`#${COMPLETE_PAYMENT_BUTTON_ID}`).on('click', () => {
        if (checkValidFields()) {
          this.completePayment();
        } else {
          $('#error-status').html('<p class="mt-1 mb-1">Please scroll up and provide all required information before continuing.</p>');
        }
      });
    },

    initiateCapture(field) {
      $(`#retry-${field}`).css('visibility', 'hidden');
      this.perform('initiate_capture', { field });
    },

    completePayment() {
      if (!this.isValid()) {
        $('.has-error').first().find('input, select').trigger('focus');
        return;
      }
      disableSubmitButton();
      disableCaptureButtons();
      this.perform('complete_payment', this.paymentParams());
    },

    paymentParams() {
      const params = {
        amount,
        date,
        agreement_id: agreementId,
      };
      if (this.suspenseDetails) {
        params.suspense_details = this.suspenseDetails.params();
      } else if (document.getElementById('card_details_card_keep')) {
        params.keep_card = document.getElementById('card_details_card_keep').checked;
      }
      return params;
    },
  });
};

$(() => {
  if ($('#pci-payment-form').length) { connectToPciPayment($('#pci-payment-form')); }
});
