class NewRepairForm {
  constructor(form) {
    this.validateField = this.validateField.bind(this);
    this.handleGrantAccessRadioChange = this.handleGrantAccessRadioChange.bind(
      this,
    );
    this.markFieldAsInvalid = this.markFieldAsInvalid.bind(this);
    this.markFieldAsValid = this.markFieldAsValid.bind(this);
    this.disableFormSubmission = this.disableFormSubmission.bind(this);
    this.enableFormSubmission = this.enableFormSubmission.bind(this);
    this.revealNextStep = this.revealNextStep.bind(this);
    this.form = form;
    this.formInputs = this.form.find(
      'input[type=tel], input[type=email], textarea',
    );
    this.grantAccessSection = this.form.find('#repair-access-granted');
    this.grantAccessRadios = this.grantAccessSection.find('input[type=radio]');
    this.accessRequestAppointmentTimesSection = $(
      '#access-request-appointment-times',
    );
    this.submitButton = $('#submit-repair-form-button');
    this.errorMsg = $('.submit-repair-missing-info');
    this.submitMsg = $('.submit-repair-request-info');
    this.nextStep = $('#repairs-next-step');

    this.attachFormValidationEvents();
    this.attachFieldEvents();
    this.attachNextStepEvents();

    NewRepairForm.hideAllSteps();
    this.validationResults = {};
  }

  static hideAllSteps() {
    $('.js-reveal').addClass('is-hidden');
    $('.submit-repair-missing-info').text(
      'Please scroll up and provide all required information before continuing',
    );
  }

  validators() {
    return this.form[0].ClientSideValidations.settings.validators;
  }

  validateRevealedForm() {
    this.formInputs.each((i, f) => {
      if (!$(f).closest('fieldset').hasClass('is-hidden')) {
        $(f).data('changed', true).isValid(this.validators());
      }
      return true;
    });
    return this.toggleFormSubmission();
  }

  validateField(e) {
    if (this.validateFieldTimeout) {
      clearTimeout(this.validateFieldTimeout);
    }
    this.validateFieldTimeout = setTimeout(() => {
      $(e.target).data('changed', true).isValid(this.validators());
      this.toggleFormSubmission();
    }, 300);
  }

  grantAccessSectionInUse() {
    return (
      this.grantAccessSection.length && this.grantAccessRadios.is(':visible')
    );
  }

  grantAccessOptionSelected() {
    return this.grantAccessRadios.is(':checked');
  }

  handleGrantAccessRadioChange() {
    if (!this.grantAccessSectionInUse()) {
      return true;
    }

    if (this.grantAccessOptionSelected()) {
      this.grantAccessSection.removeClass('has-error');
      this.grantAccessSection.find('div').removeClass('has-error');
      this.grantAccessSection.find('.help-block.has-error').hide();

      this.accessRequestAppointmentTimesSection.attr(
        'hidden',
        this.grantAccessRadios.filter(':checked').val() === 'true',
      );
      this.accessRequestAppointmentTimesSection
        .find('textarea')
        .prop(
          'disabled',
          this.grantAccessRadios.filter(':checked').val() === 'true',
        );
    } else {
      this.grantAccessSection.addClass('has-error');
      this.grantAccessSection.find('div').addClass('has-error');
      this.grantAccessSection.find('.help-block.has-error').show();
    }

    return this.toggleFormSubmission();
  }

  markFieldAsInvalid(element, message, addError) {
    this.validationResults[element.attr('id')] = false;
    return addError();
  }

  markFieldAsValid(element, removeError) {
    this.validationResults[element.attr('id')] = true;
    return removeError();
  }

  allFieldsAreValid() {
    if (!Object.keys(this.validationResults).length) {
      return false;
    }
    if (this.grantAccessSectionInUse() && !this.grantAccessOptionSelected()) {
      return false;
    }
    return Object.keys(this.validationResults).every(
      (key) => this.validationResults[key],
    );
  }

  disableFormSubmission() {
    this.submitButton.prop('disabled', true);
    this.errorMsg.removeClass('is-hidden');
    return this.submitMsg.addClass('is-hidden');
  }

  enableFormSubmission() {
    this.submitButton.prop('disabled', false);
    this.errorMsg.addClass('is-hidden');
    return this.submitMsg.removeClass('is-hidden');
  }

  toggleFormSubmission() {
    if (this.allFieldsAreValid()) {
      this.enableFormSubmission();
    } else {
      this.disableFormSubmission();
    }
    return true;
  }

  attachFormValidationEvents() {
    ClientSideValidations.callbacks.element.fail = this.markFieldAsInvalid;
    ClientSideValidations.callbacks.element.pass = this.markFieldAsValid;
  }

  attachFieldEvents() {
    this.formInputs.on('keyup', this.validateField);
    return this.grantAccessRadios.on(
      'change',
      this.handleGrantAccessRadioChange,
    );
  }

  attachNextStepEvents() {
    return this.nextStep
      .on('click', (e) => e.preventDefault())
      .on('click', this.revealNextStep);
  }

  static calculateOffset() {
    const slack = 10;
    return $('.navbar-fixed-top').outerHeight() + slack;
  }

  revealNextStep() {
    const remainingSteps = $('.js-reveal.is-hidden');
    const next = remainingSteps[0];
    if (!next) {
      return;
    }

    this.validateRevealedForm();
    this.handleGrantAccessRadioChange();

    if (remainingSteps.length === 2) {
      this.nextStep.addClass('is-hidden');
      remainingSteps.removeClass('is-hidden');
    } else {
      next.classList.remove('is-hidden');
    }

    $(document).scrollTop(0);
    $(document).scrollTop(
      next.getBoundingClientRect().top - NewRepairForm.calculateOffset(),
    );
  }
}

$(document).ready(() => {
  const $form = $('#new-repair-request form');
  if ($form.length > 0) {
    new NewRepairForm($form); /* eslint-disable-line no-new */
  }
});
