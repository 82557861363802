import { PaymentForm } from './payment_form';
import { StripeFields } from './stripe_fields';

const POSSIBLE_CONTROLLERS_RENDERING_PAYMENT_FORM = ['payment', 'suspense_payments'];

const stripeNotLoaded = () => {
  let stripeUnavailable = false;

  try {
    if (typeof Stripe === 'undefined' || Stripe === null) {
      stripeUnavailable = true;
    }
  } catch (e) {
    stripeUnavailable = true;
  }

  return stripeUnavailable;
};

$(document).ready(() => {
  if (
    typeof window.stripeMode === 'undefined'
    || window.stripeMode === null
    || !POSSIBLE_CONTROLLERS_RENDERING_PAYMENT_FORM.includes($('body').data('controller'))
  ) {
    return;
  }

  if (stripeNotLoaded()) {
    const errorMessage = document.getElementById('stripe-not-loaded');

    if (errorMessage) {
      errorMessage.hidden = false;
    }

    // only exit early if in live mode so that specs can continue
    if (window.stripeMode === 'live') {
      return;
    }
  }

  let stripeFields;

  if (window.stripeMode === 'live') {
    const stripePublicKey = $('#payment_stripe_public_key').val();

    stripeFields = new StripeFields(Stripe(stripePublicKey));
  } else if (window.stripeMode === 'test') {
    stripeFields = {
      setUp: () => {},
      submit: (args, callback) => {
        if (
          typeof window.testStripeError !== 'undefined'
          && window.testStripeError !== null
        ) {
          callback('generic_error', null, null);
        } else {
          callback('success', 'valid_stripe_token', 'debit');
        }
      },
    };
  }

  const $formEl = $('#new_payment');
  const paymentForm = new PaymentForm($formEl, stripeFields);
  $formEl.on('submit', (e) => paymentForm.submitWithStripeFields(e));
});
