// import Rollbar from '../src/rollbar';
import consumer from './consumer';

function connectToOutbound() {
  const pin = $('#pci-outbound-request').data('pin');

  consumer.subscriptions.create({ channel: 'PciOutboundChannel', pin: `${pin}` }, {
    received(data) {
      window.location.href = data.redirect_path;
    },
  });
}

$(() => {
  if ($('#pci-outbound-request').length) { connectToOutbound(); }
});
