// allows users to use keyboard to use esc for exiting the modal
$(document).ready(() => {
  $('#my-modal').on('shown.bs.modal', () => {
    $('#modal-btn').focus();
    // navigate left and right on the keyboard for the carousel
    $(document).bind('keyup', (e) => {
      // key binding for right arrow on keyboard
      if (e.which === 39) {
        $('.carousel').carousel('next');
        // key binding for left arrow on keyboard
      } else if (e.which === 37) {
        $('.carousel').carousel('prev');
      }
    });
  });
});
