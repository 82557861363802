const FIELDS_TO_CAPTURE = ['expiration-date', 'payment-card-number', 'security-code'];
const COMPLETE_PAYMENT_BUTTON_ID = 'complete-payment';
const ERROR_CODE_MESSAGES = {
  'input-timeout': 'The session has timed out. You need to request this again.',
  'invalid-card-number': "The card number isn't valid.",
  'invalid-card-type': 'The card type is not supported.',
  'invalid-date': 'Expiry date must have 2 digits for both the month and year.',
  'date-validation-failed': 'Expiry date must be today or in the future.',
  'invalid-security-code': "The security code isn't valid.",
  'invalid-postal-code': 'Enter a real post code.',
  'invalid-card-number-security-code-capture-sequence': "The security code isn't valid",
};

const hideAllRetryButtons = () => {
  FIELDS_TO_CAPTURE.forEach((field) => { $(`#retry-${field}`).css('visibility', 'hidden'); });
};

const disableSubmitButton = () => {
  $(`#${COMPLETE_PAYMENT_BUTTON_ID}`).prop('disabled', true);
};

const disableCaptureButtons = () => {
  FIELDS_TO_CAPTURE.forEach((field) => {
    $(`#${field}`).prop('disabled', true);
  });
};

const enableCaptureButtons = () => {
  FIELDS_TO_CAPTURE.forEach((field) => {
    $(`#${field}`).prop('disabled', false);
  });
};

const titleize = (field, capitalize = false) => {
  const words = field.split('-');
  if (capitalize) {
    words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
  }
  return words.join(' ');
};

const inputId = (field) => ((field === 'expiration-date') ? '#expiration-month-input, #expiration-year-input' : `#${field}-input`);

const setCaptureFieldToInactive = (field) => {
  $(`#retry-${field}`).css('visibility', 'hidden');
  $(`#${field}`).prop('disabled', false);
  $(`#${field}`).html(`Request ${titleize(field, false)}`);
  $(inputId(field)).removeClass('capture-active');
};

const setAllOtherFieldsToInactive = (field) => {
  const otherFields = FIELDS_TO_CAPTURE.filter((f) => f !== field);
  otherFields.forEach((otherField) => {
    setCaptureFieldToInactive(otherField);
    $(`#${otherField}`).html(`Request ${titleize(otherField)}`);
  });
};

const setCaptureFieldToActive = (field) => {
  setAllOtherFieldsToInactive(field);
  $(inputId(field)).addClass('capture-active');
  $(`#${field}`).prop('disabled', true);
  $(`#${field}`).html(`${titleize(field, true)} requested`);
};

// An enhanced check to make sure 'required' isn't blank by omission...
// but is definitely blank because all fields are filled in
const isCompleted = (required, inputs) => (inputs.every((field) => typeof field !== 'undefined' && field.length > 0) && required === '');

const updateCompletedFields = (completed, required) => {
  let stillRequiredFields = [];
  if (required) { stillRequiredFields = required.split(','); }

  FIELDS_TO_CAPTURE.forEach((field) => {
    if (completed || !(stillRequiredFields.includes(field))) {
      $(`#${field}-hash`).val('#');
      $(`#${field}-status`).html(`<div class='container complete-status'><i class='fa fa-check-circle'></i> Valid ${titleize(field, false)} format</div>`);
      setCaptureFieldToInactive(field);
    } else {
      $(`#${field}-hash`).val('');
      $(`#${field}-status`).html('');
    }
  });
};

const checkValidFields = () => {
  let valid = true;
  FIELDS_TO_CAPTURE.forEach((field) => {
    if ($(`#${field}-status .complete-status`).length === 0) {
      $(`#${field}-status`).html(`<div class='container error-status'><i class='fa fa-exclamation-circle'></i> Customer must enter ${titleize(field, false)} and hash (#) key.</div>`);
      valid = false;
    }
  });
  return valid;
};

const resetField = (field) => {
  $(`#${field}-hash`).val('');
  $(`#${field}-status`).html('');
  $(inputId(field)).val('');
};

const updateError = (capture, errorType) => {
  resetField(capture);
  $(`#${capture}-status`).html(`<div class='container error-status'><i class='fa fa-exclamation-circle'></i> ${ERROR_CODE_MESSAGES[errorType]}</div>`);

  setCaptureFieldToInactive(capture);
};

const setExpirationDateValues = (expirationDate) => {
  if (typeof expirationDate !== 'undefined') {
    $('#expiration-month-input').val(expirationDate.slice(0, 2));
    $('#expiration-year-input').val(expirationDate.slice(2));
  }
};

const setRetryVisibility = (capture, data) => {
  const captureFieldValue = data[capture.replace(/-/g, '_')];

  if (captureFieldValue.length > 0) {
    $(`#retry-${capture}`).css('visibility', 'visible');
    $(`#${capture}`).prop('disabled', true);
  } else {
    $(`#retry-${capture}`).css('visibility', 'hidden');
    $(`#${capture}`).prop('disabled', false);
  }
};

const updateFields = (data) => {
  const {
    expiration_date: expirationDate,
    payment_card_number: paymentCardNumber,
    security_code: securityCode,
    required,
    error_type: errorType,
    capture,
  } = data;

  $('#payment-card-number-input').val(paymentCardNumber);
  $('#security-code-input').val(securityCode);
  setExpirationDateValues(expirationDate);

  setRetryVisibility(capture, data);
  const completed = isCompleted(required, [expirationDate, paymentCardNumber, securityCode]);
  updateCompletedFields(completed, required);

  if (errorType) { updateError(capture, errorType); }
};

const handleReceivedUpdate = (data) => {
  const { field } = data;

  switch (data.event) {
    case 'InitiateCaptureFail':
      $(`#${field}-status`).html(`<div class='container error-status'><i class='fa fa-exclamation-triangle'></i> Failed to start capturing ${titleize(field, false)}</div>`);
      setCaptureFieldToInactive(field);
      break;

    case 'InitiateCaptureSuccess':
      $('#error-status').html('');
      $(`#${field}`).prop('disabled', true);
      resetField(field);
      setCaptureFieldToActive(field);
      break;

    case 'TwilioUpdate':
      if (typeof data.required !== 'undefined') {
        updateFields(data);
      }
      break;

    default:
      console.log('Other response');
  }
};

export {
  checkValidFields,
  hideAllRetryButtons,
  disableSubmitButton,
  handleReceivedUpdate,
  disableCaptureButtons,
  enableCaptureButtons,
  FIELDS_TO_CAPTURE,
  COMPLETE_PAYMENT_BUTTON_ID,
};
