$(document).on('metropolitan_contact_details.load salesforce_contact_details.load contact_details.load passwords.load', () => {
  let dirtyForm = false;
  function resetOtherEditForms() {
    // eslint-disable-next-line no-restricted-globals, no-alert
    if (dirtyForm && !confirm('You have unsaved changes. Do you want to discard them?')) {
      return false;
    }

    const $contactDetails = $('.met-content');
    $contactDetails.find('.stone-highlight')
      .toggleClass('stone-highlight')
      .find('.met-edit-contact-ui')
      .toggleClass('d-none');
    $contactDetails.find('.warning-highlight')
      .toggleClass('warning-highlight')
      .find('.met-delete-contact-ui')
      .toggleClass('d-none');
    dirtyForm = false;

    return true;
  }

  const preventSubmissionIfDisabled = (e) => {
    const disabledSubmitButton = $('.met-contact-detail input[type=submit], .met-contact-detail button').attr('disabled');
    return ((e.key === 'Enter' || e.code === 'Enter') && disabledSubmitButton);
  };

  $(
    '.met-contact-detail input[type=tel], .met-contact-detail input[type=email], .met-contact-detail input[type=text]',
  ).on('keyup', (e) => {
    if (preventSubmissionIfDisabled(e)) {
      return false;
    }
    dirtyForm = true;
    $(e.target)
      .parents('.met-contact-detail')
      .find('.btn-disable-when-empty')
      .attr('disabled', !e.target.value);
    return true;
  });

  $('.met-contact-detail form').on('submit', () => {
    $('.met-contact-detail input[type=submit], .met-contact-detail button').attr('disabled', true);
  });

  $('.toggle-confirm-deletion').on('click', (e) => {
    e.preventDefault();
    const listItem = $(e.target).parents('.met-contact-detail');
    if (!listItem.hasClass('warning-highlight') && !resetOtherEditForms()) {
      return;
    }
    listItem.find('.met-delete-contact-ui').toggleClass('d-none');
    listItem.toggleClass('warning-highlight');
  });

  $('.toggle-confirm-edit').on('click', (e) => {
    e.preventDefault();
    const listItem = $(e.target).parents('.met-contact-detail');
    if (!listItem.hasClass('stone-highlight') && !resetOtherEditForms()) {
      return;
    }
    listItem.find('.met-edit-contact-ui').toggleClass('d-none');
    listItem.toggleClass('stone-highlight');
    $('.btn-edit').prop('disabled', (i, prop) => !prop);
  });

  $('input.btn.btn-secondary.toggle-confirm-edit').on('click', (e) => {
    dirtyForm = false;
    e.target.form.reset();
  });

  $('a[data-error=true]').trigger('click');
});
