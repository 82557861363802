import Rollbar from 'rollbar';

export default new Rollbar({
  accessToken: $('meta[name=rollbar-client-access-token]').attr('content'),
  captureUncaught: true,
  captureIp: 'anonymize',
  captureUnhandledRejections: true,
  hostSafeList: ['mtvh.online', 'web.staging.mettvh.dalmatian.dxw.net', 'web-test-01.staging.mettvh.dalmatian.dxw.net', 'web-test-02.staging.mettvh.dalmatian.dxw.net'],
  addErrorContext: true,
  sendConfig: true,
  scrubTelemetryInputs: true,
  payload: {
    environment: $('meta[name=rollbar-environment]').attr('content'),
  },
});
