$(document).on('my_requests.load', () => {
  $('textarea[name="description"]').trigger('change'); // for character counter to update

  const $nextButton = $('#large-radio-next-button');
  const $largeRadioSelection = $('.large-radio-selection');
  const $noSelectionError = $('#large-radio-error-unselected');
  const $noTextError = $('#large-radio-error-no-text');
  const $textTooLongError = $('#large-radio-error-too-long');
  const $cancellationReasonText = $('#other-cancellation-reason-text');
  const $formOptions = $('.large-radio-option-input');
  const $errorsElements = [$noSelectionError, $noTextError, $textTooLongError];

  function showError(errorElement) {
    $largeRadioSelection.addClass('error');
    errorElement.attr('hidden', false);
  }

  function hideError(errorElement) {
    $largeRadioSelection.removeClass('error');
    $(errorElement).attr('hidden', true);
  }

  function resetErrors() {
    $.each($errorsElements, (_i, el) => {
      hideError(el);
    });
  }

  function isChecked() {
    return $('form input[type="radio"]:checked').length > 0;
  }

  function otherIsChecked() {
    return $('form input[value="other"]:checked').length > 0;
  }

  function otherText() {
    return $('textarea[name="description"]').val();
  }

  function submitForm(target) {
    target.parents('form').trigger('submit');
  }

  function hideDescription() {
    $('textarea[name="description"]').val('');
    $('textarea[name="description"]').trigger('change'); // for character counter to update
    $cancellationReasonText.collapse('hide');
  }

  const $form = $('#cancellation-reason-form');

  if ($form.length) {
    /*
       show description box if 'other' option is already selected on page load
      (i.e., they used the Back btn on confirm/cancel )
    */
    if (otherText().length > 0) {
      $cancellationReasonText.collapse('show');
    }

    // toggles description box when an option is clicked
    $formOptions.on('change', (e) => {
      if ($(e.currentTarget)[0].id === 'other-cancellation-reason') {
        $cancellationReasonText.collapse('show');
      } else {
        hideDescription();
      }
    });

    /*
      Show error:
      * if no option selected, or
      * if 'other' selected and no description.
      Otherwise submit form
    */

    $nextButton.on('click', (e) => {
      e.preventDefault();

      resetErrors();
      if (!isChecked()) {
        showError($noSelectionError);
      } else if (otherIsChecked()) {
        if (otherText().length < 1) {
          showError($noTextError);
        } else if (otherText().length > 1000) {
          showError($textTooLongError);
        } else {
          submitForm($(e.currentTarget));
        }
      } else {
        submitForm($(e.currentTarget));
      }
    });

    $('form input[type="radio"]').on('change', () => {
      resetErrors();
    });
  }
});
