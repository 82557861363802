const countDownTimer = (spanElement, givenMinutes, givenSeconds) => {
  let minutes = givenMinutes;
  let seconds = givenSeconds;
  if (seconds < 10) {
    spanElement.html(`${minutes}:0${seconds}`);
  } else {
    spanElement.html(`${minutes}:${seconds}`);
  }

  if (!((minutes === 0) && (seconds === 0))) {
    setTimeout(() => {
      seconds -= 1;
      if (seconds < 0) {
        minutes -= 1;
        seconds = 59;
      }
      countDownTimer(spanElement, minutes, seconds);
    }, 1000);
  } else {
    spanElement.parents('.part').addClass('pin-expired');
    spanElement.parents('p').html(`This PIN has expired. <a href="?${Date.now()}">Retry</a>`);
  }
};

$(document).ready(() => {
  const spanElement = $('.countdown_timer');
  if (spanElement) {
    countDownTimer(spanElement, spanElement.data('minutes'), 0);
  }
});
