document.addEventListener('DOMContentLoaded', () => {
  const gaAnalyticsDivs = document.getElementsByClassName('ga4-analytics');
  window.dataLayer = window.dataLayer || [];

  Array.prototype.forEach.call(gaAnalyticsDivs, (gaDiv) => {
    const pageEvent = gaDiv.getAttribute('pageEvent');
    const requestData = JSON.parse(gaDiv.getAttribute('eventData'));

    const data = { event: pageEvent, ...requestData };
    window.dataLayer.push(data);
  });
});
