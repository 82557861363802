$(() => {
  function storeState($section, isExpanded) {
    const $container = $section.closest('.collapsible-container');
    const contentId = $container.data('resource-id');
    window.sessionStorage.setItem(contentId, isExpanded ? 'true' : 'false');
  }

  function controlIconElement($container) {
    return $container.find('.collapsible-controller .collapsible-control-icon');
  }

  function updateArrowDirection($container, isExpanded) {
    const $icon = controlIconElement($container);
    if (isExpanded) {
      $icon.addClass('arrow-up-big').removeClass('arrow-down-big');
    } else {
      $icon.addClass('arrow-down-big').removeClass('arrow-up-big');
    }
  }

  function applyState() {
    $('.collapsible-content').each(function () {
      const $container = $(this).closest('.collapsible-container');
      const contentId = $container.data('resource-id');
      const isExpanded = window.sessionStorage.getItem(contentId) === 'true';
      if (isExpanded) {
        $(this).addClass('show');
      } else {
        $(this).removeClass('show');
      }
      updateArrowDirection($container, isExpanded);
    });
  }

  applyState();

  $(window).on('resize', () => {
    applyState();
  });

  $('.collapsible-content').on('hide.bs.collapse', function () {
    storeState($(this), false);
    updateArrowDirection($(this).closest('.collapsible-container'), false);
  });

  $('.collapsible-content').on('show.bs.collapse', function () {
    storeState($(this), true);
    updateArrowDirection($(this).closest('.collapsible-container'), true);

    const expandUrl = $(this).closest('.collapsible-container').data('expand-url');
    if (expandUrl) {
      $.post(expandUrl);
    }
  });

  const params = new URLSearchParams(window.location.search);
  const dismissable = params.has('dismiss');

  if (dismissable) {
    $(window).on('load', () => {
      $('.collapsible-content#updates-collapsible-desktop, .collapsible-content#updates-collapsible-mobile').collapse();
    });
  }
});
