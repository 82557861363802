import consumer from './consumer';

const showConfirmationPanel = () => {
  $('#confirmation-panel').attr('hidden', false);
  $('#loading-skeleton').attr('hidden', true);
};

const connectToSalesforceEnquiry = () => {
  const $confirmationPanel = $('#confirmation-panel');
  const serviceRequestId = $confirmationPanel.data('service-request-id');
  const confirmationTimeout = parseInt($confirmationPanel.data('confirmation-timeout'), 10);
  const loadingTimer = setTimeout(() => {
    showConfirmationPanel();
  }, confirmationTimeout);

  consumer.subscriptions.create({ channel: 'SalesforceEnquiryChannel', service_request_id: serviceRequestId }, {
    received(data) {
      if (data.reference) {
        $('#confirmation-reference').text(data.reference).parents('p').addClass('bg-stone pr-1 pl-1');
        $('#first-response-date').text(`(by ${data.first_response_date})`).attr('hidden', false);
        $('#my-request-link').attr('href', data.my_request_path);
        showConfirmationPanel();
        clearTimeout(loadingTimer);
      }
    },
  });
};

$(() => {
  if ($('#confirmation-panel').length) { connectToSalesforceEnquiry(); }
});
