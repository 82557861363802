$(() => {
  const $notifications = $('[data-notification]');

  if ($notifications.length > 0) {
    $notifications.each((index, notification) => {
      const $notification = $(notification);
      const target = $notification.get(0);
      const $closeButton = $notification.find('.notifications-block__dismiss, .my_request__notification_dismiss');
      const undoDismissUrl = $closeButton.data('undo-dismiss-url');
      $notification.data.clearUndoTimer = 0;

      const footerScrolled = () => {
        const docViewTop = $(window).scrollTop();
        const docViewBottom = docViewTop + $(window).height();
        const elemTop = $('footer.footer').offset().top;

        return docViewBottom - elemTop;
      };
      const fixUndoPanelPosition = () => {
        const footerInViewPosition = footerScrolled();
        if ($('.swipe__undo_panel').length && footerInViewPosition > 0) {
          $('.swipe__undo_panel').css('bottom', `${footerInViewPosition + 10}px`);
        } else {
          $('.swipe__undo_panel').css('bottom', '10px');
        }
      };
      const hideUndoPanel = (cb) => {
        if (!$('.swipe__undo_panel').length && cb) { cb(); return; }
        $('.swipe__undo_panel').animate({
          opacity: 0,
          left: `-${$('body').width() * 2}`,
        }, 200, () => {
          $('.swipe__undo_panel').remove();
          if (cb) {
            cb();
          }
        });
      };
      const showUndoPanel = () => {
        const $undoPanel = $('<div class="swipe__undo_panel" style="opacity: 0">Notification dismissed <a href="#">Undo</a></div>');
        $undoPanel.css('left', $('body').width() * 2);
        hideUndoPanel(() => {
          $('body').append($undoPanel);
          fixUndoPanelPosition();
          $undoPanel.animate({
            opacity: 1,
            left: '50%',
          }, 200);
        });
        $notification.data.clearUndoTimer = setTimeout(() => {
          hideUndoPanel();
        }, 8000);
        $undoPanel.on('click', (e) => {
          e.preventDefault();
          e.stopPropagation();
          clearTimeout($notification.data.clearUndoTimer);
          $.post(undoDismissUrl);
          target.style.transform = null;
          target.style.boxShadow = null;
          target.style.transition = null;
          hideUndoPanel();
          $('.notifications__empty').addClass('d-none');
          $('.notifications-page__dismiss-all, .notifications-count').removeClass('d-none');
          $notification.slideDown(300, () => {
            $notification.fadeTo(100, 1);
          });
        });
      };

      $(window).on('scroll resize', fixUndoPanelPosition);

      $closeButton.on('ajax:before', () => {
        clearTimeout($notification.data.clearUndoTimer);
        $notification.fadeTo(100, 0, () => {
          $notification.slideUp(200, () => {
            $notification.hide();
            showUndoPanel();
            if ('vibrate' in navigator) {
              navigator.vibrate(300);
            }

            if ($('[data-notification]:visible').length === 0) {
              $('.notifications__empty').removeClass('d-none');
              $('.notifications-page__dismiss-all, .notifications-count').addClass('d-none');
            }
          });
        });
      });
    });
  }
});
