/**
 * Turns a list into dissmissable items, currently supports dismissed by a
 * remote trigger upon `ajax:success` response.
 * Usage:
 * <ul data-behaviour="dismissable-list" data-dismissable-list-empty-target="#empty-list-message">
 *  <li data-dismissable-list-role="list-item" id="item-1">
 *     Hello world
 *    <button
 *      onclick="triggerRemoteAction()"
 *      data-dismissable-list-role="remote-trigger"
 *      data-dismissable-list-target="#item-1"
 *     >Click me</button>
 *  </li>
 * </ul>
 * <span id="empty-list-message">List is empty</span>
 */

const listItemSelector = '[data-dismissable-list-role="list-item"]';
const listItemRemoteTriggerSelector = '[data-dismissable-list-role="remote-trigger"]';

document.addEventListener('DOMContentLoaded', () => {
  document
    .querySelectorAll('[data-behaviour~="dismissable-list"]')
    .forEach((list) => {
      const emptyMessageEl = document.querySelector(list.dataset.dismissableListEmptyTarget);

      list
        .querySelectorAll(listItemRemoteTriggerSelector)
        .forEach((trigger) => {
          $(trigger).on('ajax:success', () => {
            const targetSelector = trigger.dataset.dismissableListTarget;
            const targetEl = document.querySelector(targetSelector);
            if (targetEl) {
              targetEl.remove();
            }

            const numberOfRemainingItems = list.querySelectorAll(listItemSelector).length;

            if (emptyMessageEl && numberOfRemainingItems === 0) {
              emptyMessageEl.hidden = false;
            }
          });
        });
    });
});
