$(() => {
  $("input[name='magic_code[]']").on('input', function nextInput() {
    $(this).next().trigger('focus');
  });

  $('#magic-code-form input').on('invalid', () => {
    $('.error-code').removeClass('d-none');
    return false;
  });
});
