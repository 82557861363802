/**
 * This script is specific to the Met /repairs/request space selection
 * form to deal with the lack of client side validation on the radio
 * button group input, instead the submit button will be disabled until a
 * repair space is selected or the page is loaded with one pre-selected.
 */
$(document).on('new_repairs.load', () => {
  const $repairSpaceSelectionButton = $('#repair-space-next-button');
  const $repairSpaceSelection = $('.repair-space-selection');
  const $repairSpaceError = $('#repair-space-error');

  function showError() {
    $repairSpaceSelection.addClass('error');
    $repairSpaceError.attr('hidden', false);
  }

  function hideError() {
    $repairSpaceSelection.removeClass('error');
    $repairSpaceError.attr('hidden', true);
  }

  function isChecked() {
    return $('form.repair input[type="radio"]:checked').length > 0;
  }

  $repairSpaceSelectionButton.on('click', (e) => {
    if (!isChecked()) {
      e.preventDefault();
      showError();
    }
  });

  $('form.repair input[type="radio"]').on('change', () => {
    hideError();
  });
});
