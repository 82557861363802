export class PaymentForm {
  constructor(el, stripeFields) {
    this.el = el;
    this.stripeFields = stripeFields;
    this.stripeFields.setUp();
    this.submitButton = this.el.find('input[type=submit]');
    this.originalSubmitBtnText = this.submitButton.val();
    this.enableSubmitButton();
  }

  enableSubmitButton() {
    this.submitButton.val(this.originalSubmitBtnText);
    this.submitButton.prop('disabled', false);
  }

  disableSubmitButton() {
    this.submitButton.val(this.submitButton.data('disabled-message'));
    this.submitButton.prop('disabled', true);
  }

  submitWithStripeFields(e) {
    this.hideGenericError();
    this.disableSubmitButton();

    if (this.isUsingPaymentIntent()) { this.setFormDestination(this.immediatePaymentPath()); }
    if (this.isUsingSetupIntent()) { this.setFormDestination(this.futurePaymentPath()); }

    if (this.isUsingSavedCard()) {
      return;
    }

    e.preventDefault();

    this.stripeFields.submit(this.optionalArgs(), (result, id) => {
      if (result === 'success') {
        this.prepareForm(id);
        this.submitForm();
        return;
      }

      if (result === 'generic_error') {
        this.displayGenericError();
      }

      setTimeout(() => this.enableSubmitButton(), 500);
    });
  }

  optionalArgs() {
    return {
      name: this.el.find('input[name="payment[card][cardholder_name]"]').val(),
    };
  }

  prepareForm(id) {
    return this.appendPaymentMethod(id);
  }

  setFormDestination(path) {
    return this.el.attr('action', path);
  }

  submitForm() {
    if (typeof (this.el.get(0).submit) === 'function') {
      // this method of form submission is required for Poltergeist tests (spec/features)
      return this.el.get(0).submit();
    }
    return this.el.submit();
  }

  isUsingSavedCard() {
    return this.el.find('input[name="payment[card_alias]"]:checked').length > 0;
  }

  isImmediatePayment() {
    return this.el.find('input[name="payment[date]"]').val()
      === this.el.find('#todays_date').val();
  }

  isKeepingCard() {
    return this.el.find('input[name="payment[card][keep]"]').is(':checked');
  }

  isUsingPaymentIntent() {
    return this.isImmediatePayment();
  }

  isUsingSetupIntent() {
    return !this.isImmediatePayment();
  }

  appendPaymentMethod(id) {
    return this.el.append($('<input type="hidden" name="payment[payment_method_id]" />').val(id));
  }

  hideGenericError() {
    return this.el.find('#stripe-error').addClass('d-none');
  }

  displayGenericError() {
    return this.el.find('#stripe-error')
      .text('Oops. Something went wrong! Please try again.')
      .removeClass('d-none');
  }

  immediatePaymentPath() {
    return this.el.find('#immediate_payments_path').val();
  }

  futurePaymentPath() {
    return this.el.find('#future_payments_path').val();
  }
}
