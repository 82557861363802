$(() => {
  const $enquiryTypeField = $('input[name="form[salesforce_enquiry_type]"]');
  const $enquirySubTypeField = $('select[name="form[salesforce_enquiry_sub_type]"]');

  if ($enquirySubTypeField.length) {
    $enquirySubTypeField.on('change', () => {
      const $option = $enquirySubTypeField.find('option:selected');
      $enquiryTypeField.val($option.parent().attr('label') || '');
    });

    // The enquiry sub types are not unique, in these cases Rails will select the
    // last one that matches. This JavaScript will select the correct one.
    const $selected = $enquirySubTypeField.find(`[label='${$enquiryTypeField.val()}']`).find(`[value='${$enquirySubTypeField.val()}']`);
    if ($selected.length) {
      $selected.prop('selected', true);
    }
  }
});
